
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  computed,
  ComputedRef,
  ref,
  nextTick,
  watch
} from 'vue'
import OrderTable, { TableColumnItem } from '@/components/filter-table/Index.vue'
import { requestOrderList, GetOrderList } from '@/apis/order'
import requestMiddle, { RequestInter } from '@/utils/request'
import { parseTime } from '@/utils'
import { IndexMessage, AgentType } from '@/model/orderModel'
// import Tips from '@/components/tips/Index.vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { DeviceType } from '@/store/modules/app/state'
// import { parseInt } from 'lodash'
interface DataInter{
  tableData: IndexMessage<string>[]
  exportData: IndexMessage<string>[]
  commission: string
  tableColumn: TableColumnItem[]
  isLoading: boolean
  month: string
  isSecondary: boolean
  listTotal: number
  agentName: string
  title: string
  agentId: string
  getOrderList: (option?: GetOrderList, callback?: () => void, failCb?: () => void) => void
}
export default defineComponent({
  components: {
    // PlateTitle
    // Tips,
    OrderTable
  },
  setup() {
    const orderTableRef = ref(null)
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const agentType: ComputedRef<number> = computed(() => {
      return store.state.user.userinfo.agent_type
    })
    const tableColumn = {
      distributor: [
        {
          prop: 'serial',
          label: '序号',
          width: '80'
        },
        {
          prop: 'date',
          label: '添加时间',
          minWidth: '180'
        },
        {
          prop: 'transaction_id',
          label: '订单号',
          // width: '200'
          minWidth: '180'
        },
        {
          prop: 'product_name',
          label: '产品名称',
          minWidth: '180'
        },
        {
          prop: 'agent_name',
          label: '二级代理',
          width: '180',
          hascope: true
        },
        {
          prop: 't_status_text',
          label: '状态',
          width: '80'
        },
        {
          prop: 'amount',
          label: '金额',
          width: '80'
        },
        {
          prop: 'platform',
          label: '交易平台',
          width: '80'
        },
        // {
        //   prop: 'rate',
        //   label: '手续费率',
        //   width: '80'
        // },
        // {
        //   prop: 'rate_fee',
        //   label: '手续费',
        //   width: '80'
        // },
        // {
        //   prop: 'net_amount',
        //   label: '净额',
        //   width: '80'
        // },
        {
          prop: 'cost_price',
          label: '成本价',
          width: '80'
        },
        {
          prop: 'commission',
          label: '佣金',
          width: '80'
        }
      ],
      divide: [
        {
          prop: 'serial',
          label: '序号',
          width: '80'
        },
        {
          prop: 'date',
          label: '添加时间',
          width: '180'
        },
        {
          prop: 'transaction_id',
          label: '订单号',
          // width: '200'
          minWidth: '180'
        },
        {
          prop: 'product_name',
          label: '产品名称',
          width: '180'
        },
        {
          prop: 'agent_name',
          label: '二级代理',
          width: '180',
          hascope: true
        },
        {
          prop: 't_status_text',
          label: '状态',
          width: '80'
        },
        {
          prop: 'is_auto',
          label: '缴费类型',
          width: '80'
        },
        {
          prop: 'buy_type',
          label: '购买类型',
          width: '80'
        },
        {
          prop: 'amount',
          label: '金额',
          width: '80'
        },
        {
          prop: 'platform',
          label: '交易平台',
          width: '80'
        },
        {
          prop: 'rate',
          label: '手续费率',
          width: '80'
        },
        {
          prop: 'rate_fee',
          label: '手续费',
          width: '80'
        },
        {
          prop: 'refund',
          label: '退款金额',
          width: '80'
        },
        {
          prop: 'net_amount',
          label: '净额',
          width: '80'
        },
        {
          prop: 'commission_rate',
          label: '佣金比例 ',
          width: '80'
        },
        {
          prop: 'commission',
          label: '佣金',
          width: '80'
        }
      ]
    }
    const data: DataInter = reactive({
      exportData: [],
      tableData: [],
      commission: '',
      listTotal: 0,
      month: '',
      isSecondary: false,
      isLoading: true,
      agentName: '',
      title: '',
      agentId: '',
      tableColumn: tableColumn.divide,
      getOrderList: (option, successCb?, failCb?) => {
        const newOption = {
          agent_id: data.agentId
        }
        nextTick(async() => {
          if (orderTableRef.value) {
            const { startTime, endTime, currentPage, pageSize } = orderTableRef.value as any
            const filterData = {
              start_time: startTime,
              end_time: endTime,
              page: currentPage,
              size: pageSize,
              search_key: data.title,
              agent_id: data.agentId
            }
            Object.assign(newOption, filterData, option)
          }
          const requestData = await requestMiddle({
            requestMethodName: requestOrderList,
            option: newOption
          } as RequestInter)
          try {
            const { transaction, commission, agentName } = requestData
            const dataType = option?.export === 1 ? 'exportData' : 'tableData'
            data[dataType] = transaction?.data.map((item: IndexMessage< string | number | null>) => {
              item.date = parseTime(item.addtime)
              item.is_auto = item.is_auto === 0 ? '自动' : '手动'
              item.rate = (item.rate as number * 100) + '%'
              item.buy_type = item.buy_type === 1 ? '正常购买' : '手动续费'
              item.serial = item.id
              !item.agent_name && (item.agent_name = '-')
              return item
            })
            data.agentName = agentName
            data.commission = commission?.all
            data.month = commission?.month
            data.listTotal = transaction.total
            successCb && successCb()
          } catch (error) {
            console.log(error)
            failCb && failCb()
          }
          data.isLoading = false
        })
      }
    })

    const tipPlace = computed(() => {
      if (store.state.app.device === DeviceType.Mobile) {
        return 'bottom'
      } else {
        return 'right'
      }
    })

    const orderMethods = reactive({
      columnClick: (row: Array<IndexMessage<string> | string>) => {
        if (row[0] === 'agent_name' && (row[1] as IndexMessage<string>).agent_name !== '-') {
          router.push({
            name: 'SecondaryList',
            params: { id: (row[1] as IndexMessage<string>).agent_id, name: (row[1] as IndexMessage<string>).agent_name }
          })
        }
      },
      handleFilter: () => {
        data.getOrderList({
          search_key: data.title
        })
      },
      handleClear: () => {
        data.title = ''
      },
      setTableColumn: () => {
        data.tableColumn = data.tableColumn.filter(item => {
          return item.prop !== 'agent_name'
        })
      }
    })

    onBeforeMount(() => {
      if (route.name === 'SecondaryOrder') { // 二级代理系统访问订单
        data.isSecondary = true
        data.agentId = store.state.user.userinfo.id
        data.agentName = store.state.user.userinfo.agent_name
        orderMethods.setTableColumn()
        data.getOrderList()
      }
    })

    watch(() => route.name, () => {
      if (agentType.value === AgentType.DISTRIBUTOR) { // 分销商
        data.tableColumn = tableColumn.distributor
      } else {
        data.tableColumn = tableColumn.divide
      }
      if (route.name === 'SecondaryList') { // 一级代理系统二级代理订单
        data.isSecondary = true
        console.log(route.params)
        data.agentId = route.params.id as string
        orderMethods.setTableColumn()
        data.getOrderList()
      }
      if (route.name === 'OrderIndex') {
        data.isSecondary = false
        data.agentId = '0'
        data.getOrderList()
      }
    }, { immediate: true })

    return {
      ...toRefs(data),
      ...toRefs(orderMethods),
      orderTableRef,
      tipPlace
    }
  }
})
